export const developerSkills = {
  program: [
    { name: ["HTML", "CSS", "SCSS"], year: 2020, month: 4, fixed: 0 },

    {
      name: ["JavaScript"],
      year: 2020,
      month: 5,
    },
    {
      name: ["TypeScript"],
      year: 2020,
      month: 12,
    },
    {
      name: ["NodeJs", "MongoDB"],
      year: 2020,
      month: 12,
    },
    {
      name: ["React"],
      year: 2021,
      month: 5,
    },
    {
      name: ["Hardhat"],
      year: 2022,
      month: 1,
    },
    {
      name: ["ethers.js"],
      year: 2021,
      month: 12,
    },
    { name: ["Solidty"], year: 2022, month: 1 },
    { name: ["C"], year: 2022, month: 6 },

    {
      name: ["PHP", "SQL"],
      year: 2022,
      month: 5,
    },

    { name: ["Bash"], year: 2022, month: 3 },
    { name: ["Git"], year: 2020, month: 8 },
    {
      name: ["Material UI"],
      year: 2020,
      month: 7,
    },
    {
      name: ["Tailwind", "Bootstrap", "MUI"],
      year: 2022,
      month: 7,
    },

    {
      name: ["Moralis SDK"],
      year: 2021,
      month: 11,
    },
    { name: ["particles.js", "chart.js"], year: 2022, month: 2 },
    { name: ["styled-components"], year: 2021, month: 10 },
  ],
  librairies: [],
};

export const designerSkills = [
  { name: ["Photoshop"], year: 2017, month: 3 },
  { name: ["Illustrator"], year: 2017, month: 9 },
  { name: ["InDesign"], year: 2016, month: 1, fixed: 12 },
  { name: ["After Effect"], year: 2016, month: 1, fixed: 8 },
  { name: ["Figma"], year: 2018, month: 1, fixed: 36 },
];

export const aboutMe = {
  languages: [
    { country: "FR", lvl: "Native" },
    { country: "US", lvl: "Fluent" },
    { country: "ES", lvl: "Professional Ability" },
    { country: "PT", lvl: "Basic Understanding" },
  ],
  personality: ["Self-starter", "Curious", "Empathic", "Open-minded"],
  otherSkills: ["UI/UX", "Blockchain savvy", "Microsoft Suite"],
  hobbies: [
    "New tech",
    "Football",
    "Design & Conception",
    "Experiences & discovery",
    "Karate",
    "Social Media",
  ],
  certifications: [
    {
      name: "Top 3 Polygon BUIDLIT 2022 Hackathon",
      description: "Delivered by Polygon in September 2021",
    },
    {
      name: "Web3 dApp Programming",
      description: "Delivered by Moralis in May 2022",
    },
    {
      name: "French Scientific Baccalaureate",
      description: "2017 with distinction (Option Maths)",
    },
    {
      name: "IELTS (7.0)",
      description:
        "Listening (6.5) - Reading (8.0)\nWriting (5.5) - Speaking (7.0)",
    },
    {
      name: "Google Analytics Certificate",
      description: "July 2020",
    },
  ],
};

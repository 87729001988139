export const experiences = {
  professional: [
    {
      date: "AUGUST 2022 - OCTOBER 2022",
      title: "Web3 Developer",
      company: "Rainbows.app",
      href: "https://linktr.ee/rainbowsdao",
      type: "freelancer",
      img: "rainbows",
      done: [
        "Full Stack development",
        "Smart contract integration",
        "Storybook UI components",
      ],
    },
    {
      date: "FEB 2022 - JUNE 2022",
      title: " Web3 Swiss Knife",
      company: "Cosmic Exodus",
      href: "https://www.https://cosmicexodus.xyz",
      type: "freelancer",
      img: "cosmicexodus",
      done: [
        "dApp development",
        "Tokenomics engineering",
        "Financial Planning",
        "Partners outreach",
        "Speech to investors",
        "Private funding round accompaniement",
      ],
    },
    {
      date: "DEC 2021 - JAN 2022",
      title: "Software tester",
      company: "Cajoo Technology",
      href: "https://www.cajoo.eu/",
      type: "freelancer",
      img: "cajoo",
      done: [
        "Large data sets manipulation",
        "Creating documentation",
        "Preparing software tests",
        "Composing defect reports",
        "Analytical and logical reasoning",
      ],
    },
    {
      date: "NOV - DEC 2021",
      title: "Content Operator Spain",
      company: "ManoMano",
      href: "https://www.manomano.es/",
      type: "freelancer",
      img: "manomano",
      done: [
        "Remain on-brand and compliant",
        "Data extraction/cleaning",
        "Efficiency, productivity, quality",
      ],
    },
    {
      date: "MAY - JULY 2020",
      title: "Platform Officer",
      company: "Henri Wartner Group",
      href: "https://www.henriwartner.fr/",
      type: "intership",
      img: "hwg",
      done: [
        "Support of a new business development (manufacturing and distribution of certified mask in B2B/B2C) during the covid crisis",
        "Conception and Design of the brand identity",
        "Price study",
        "Online sale platform implementation on Wordpress",
      ],
    },
    {
      date: "MAY - JULY 2018",
      title: "Assistant Project Manager",
      type: "internship",
      company: "TBWA GROUP",
      href: "https://www.tbwa-paris.com/fr",
      img: "tbwa",
      done: [
        "Benchmarking for various leading companies in the Agriculture or Public Transport sectors.",
        "Data extraction and analysis for a French mental health clinic.",
        "Development of the brand architecture of a food distribution group.",
        "Project manager of a group of 3 people in charge of innovating the multimedia communication strategy of Zakka Agency.",
      ],
    },
    {
      date: "JULY 2015",
      title: "Assistant Designer",
      type: "internship",
      company: "Jesus & Gabriel",
      href: "https://jesusetgabriel.com/",
      img: "jesusetgabriel",
      done: [
        "First concrete approach of the working world in a Marketing and Communication agency.",
      ],
    },
  ],
  personnal: [
    {
      date: "APR 2021 - TODAY",
      title: "Blockchain Engineer",
      company: "Blockswan",
      href: "https://github.com/BlockSwan",
      done: [
        "Development of a decentralized and non-custodial digital services marketplace (WIP)",
        "NodeJs Express API & IPFS cluster development",
        "Solidity smart contract engineering & implementation",
        "UI/UX design",
        "React Front-end integration",
        "Marketing",
      ],
    },
    {
      date: "2014 - TODAY",
      title: "Designer",
      company: "Oscar Design",
      href: "https://www.behance.net/OscarDzn",
      done: [
        "Sale of graphic creations (logos, banners, webdesign, jerseys...) and webApps development services.",
        "Marketing in the e - sports / crypto market through social networks.",
      ],
    },
    {
      date: "2014",
      title: "Brand Creator",
      company: "SAVAGED",
      href: "https://drive.google.com/drive/folders/1TY1Q4lEoe9wMu6785MTKMO-pmnm8X7yN?usp=sharing",
      done: [
        "Design, production, promotion and local distribution of a small clothing collection.",
      ],
    },
  ],
  academic: [
    {
      title: "Computer Sciences",
      course: [
        {
          university: "42",
          date: "SEP 2022",
          description:
            "Computer sciences and information technology in peer-to-peer learning",
        },
      ],
    },
    {
      title: "Economics & Business Management",
      course: [
        {
          university: "ESCP",
          date: "SEP 2020 - JULY 2021",
          description: "First year of Bachelor's program on London campus.",
        },
        {
          university: "Paris Dauphine | UC3M",
          date: "SEPT 2017 - JULY 2019",
          description:
            "2-year Bachelor's program in Madrid with courses in Spanish and English. Selective admission of 40 students.",
        },
      ],
    },
    {
      title: "Others",
      course: [
        {
          university: "School and High School",
          date: "SEP 2007 - AUG 2017",
          description:
            "At Saint-Jean de Passy & Assomption de Lubeck in Paris.",
        },
        {
          university: "GeorgeTown University",
          date: "JULY - SEP 2017",
          description:
            "TOP 14 US. Courses in Psychology / Sociology, Sports Law & Computer Sciences in total immersion in an American University at D.C.",
        },
      ],
    },
  ],
};

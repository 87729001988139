export const portfolio = {
  tags: [
    "Javascript",
    "TypeScript",
    "React",
    "CSS",
    "SASS",
    "MUI",
    "styled-components",
    "Bootstrap",
    "Solidity",
    "Game",
    "PHP",
    "mySQL",
    "Moralis",
    "Photoshop",
    "Illustrator",
    "Brand Identity",
    "Printed",
  ],
  projects: [
    {
      title: "Ultra Tech Alliance",
      tags: ["TypeScript", "React", "MUI"],
      github: "https://github.com/ultra-alliance/interface",
      img: "tech-alliance",
      prod: "https://interface-ultra-alliance.vercel.app",
    },
    {
      title: "Ultra Utilities Packages",
      tags: ["Javascript", "TypeScript", "React", "MUI"],
      github: "https://github.com/ultra-alliance/ultra-utilities",
      img: "ultra-utilities",
      prod: "https://stunning-duckanoo-b3cfa4.netlify.app",
    },
    {
      title: "Blockswan Protocol",
      tags: [
        "TypeScript",
        "React",
        "SASS",
        "Solidity",
        "Moralis",
        "MUI",
        "styled-components",
      ],
      github: "https://github.com/BlockSwan/blockswan-protocol",
      img: "family",
    },
    {
      title: "Rainbows DAO",
      tags: ["TypeScript", "React", "Solidity", "MUI"],
      github: "https://github.com/Rainbows-DAO",
      img: "rainbows",
    },
    {
      title: "Cosmic DEX - NFT Market",
      tags: [
        "TypeScript",
        "SASS",
        "Moralis",
        "Solidity",
        "React",
        "styled-components",
      ],
      github: "https://github.com/Oscarmacieira/Cosmic-dApp",
      chain: "AVAX",
      img: "testnet",
    },
    {
      title: "Asset Tracker v1",
      tags: ["Javascript", "React", "CSS", "Moralis"],
      github: "https://github.com/BlockSwan/website-V2",
      img: "blockswan",
    },
    {
      title: "Game Particle",
      tags: ["Game", "Javascript", "CSS", "Moralis"],
      github: "https://github.com/Oscarmacieira/GameParticle",
      img: "gameparticle",
      prod: "https://oscarmacieira.github.io/GameParticle/",
    },
    {
      title: "Ninja Fighter",
      tags: ["Game", "Javascript", "CSS"],
      github: "https://github.com/Oscarmacieira/NinjaFighter",
      img: "ninjafighter",
      prod: "https://oscarmacieira.github.io/NinjaFighter/",
    },
    {
      title: "Coin Trainor",
      tags: ["Game", "Javascript", "CSS"],
      github: "https://github.com/Oscarmacieira/CoinTrainor",
      img: "cointrainer",
      prod: "https://oscarmacieira.github.io/CoinTrainor/",
    },

    {
      title: "Cosmic Landing Page",
      tags: ["Javascript", "React", "CSS", "Moralis"],
      github: "https://github.com/Oscarmacieira/Cosmic-website",
      img: "v3",
    },

    {
      title: "Asset Tracker v0",
      tags: ["Javascript", "CSS", "PHP", "Moralis", "mySQL", "Bootstrap"],
      github: "https://github.com/BlockSwan/website-v1",
      img: "blockswanV1",
    },
    {
      title: "Discord BotSwan",
      tags: ["Javascript"],
      github: "https://github.com/BlockSwan/BotSwan",
      img: "BotSwan",
    },

    {
      title: "Solidity Parking Contracts",
      tags: ["Solidity"],
      github: "https://github.com/Oscarmacieira/ParkingContracts",
      img: "parking",
    },
    {
      title: "Logo Folio",
      tags: ["Illustrator"],
      behance: "https://www.behance.net/gallery/23885671/Logo-Folio-2",
      img: "logofolio",
    },
    {
      title: "Hunt or get Hunted",
      tags: ["Illustrator", "Printed"],
      behance:
        "https://www.behance.net/gallery/28671007/Hunt-or-get-hunted-%28Illustration%29",
      img: "huh",
    },
    {
      title: "eSport Mascots",
      tags: ["Illustrator"],
      behance: "https://www.behance.net/gallery/17335269/Sports-Logos",
      img: "esport",
    },
    {
      title: "Jersey Designs",
      tags: ["Illustrator", "Photoshop", "Printed"],
      behance: "https://www.behance.net/gallery/22813281/Jersey-Design",
      img: "jersey",
    },
    {
      title: "Twitter Banners",
      tags: ["Photoshop"],
      behance: "https://www.behance.net/gallery/17270929/Header-Twitter",
      img: "twitter",
    },
    {
      title: "Personal Branding",
      tags: ["Illustrator", "Brand Identity", "Photoshop"],
      behance: "https://www.behance.net/gallery/21497405/Personal-Branding",

      img: "personal",
    },
    {
      title: "Rasta Skull",
      tags: ["Illustrator"],
      behance: "https://www.behance.net/gallery/25613919/Rasta-Skull",
      img: "rasta",
    },
  ],
};

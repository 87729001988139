export const colorPalette = {
  darkMode: {
    color1: "#00E693",
    color2: "#016D53",
    color3: "#016D53",
    color4: "#088365",
    color5: "#164338",
    color6: "#021611",
    primary: "#0d0d0d",
    secondary: "#0d0208",
    tertiary: "#785A28",
    contrast: "#d5d3d3",
    shy: "#b6d5f6",
    light: "#00000082",
    error: "#e85653",
  },

  // Unofficial - Test purposes only
  lightMode: {
    color1: "#CDFAFA",
    color2: "#9ED7D7",
    color3: "#0AC8B9",
    color4: "#0AC8B9",
    color5: "#0596AA",
    color6: "#011619",

    primary: "#CDFAFA",
    secondary: "#002f36",
    tertiary: "#785A28",
    contrast: "#191c2d",
    shy: "#b6d5f6",
    light: "#00000082",
    error: "#e85653",
  },
};

export const typography = {
  heading: "JetBrains Bold",
  text: "JetBrains",
};
